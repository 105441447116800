<template>
    <div class="account-wrap ptb-100">
        <div class="container">
            <div class="section-title style1 text-center mb-40">
                <span>Loan Form</span>
                <h2 class="header-blue-tf-color">Apply For Loan</h2>
            </div>
            <form
                @submit.prevent="Sendform()"
                class="account-form">
                <div class="row">
                    <br />
                    <!-- Co-Applicant Information -->
                    <div class="col-md-12">
                        <h4 class="header-blue-tf-color">Loan Information</h4>
                    </div>
                    <br /><br />
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="LoanAmount"><strong>Loan Amount</strong></label>
                            <input
                                type="text"
                                id="LoanAmount"
                                name="LoanAmount"
                                v-model="this.LoanAmount"
                                disabled="disabled" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Loan_Id"><strong>Loan Type</strong></label>
                            <input
                                type="text"
                                id="LoanTypeVisible"
                                name="LoanTypeVisible"
                                v-bind:value="this.Loan_Id == 1 ? 'Personal Loan' : 'Car Loan'"
                                disabled="disabled" />
                            <input
                                type="hidden"
                                id="LoanType"
                                name="LoanType"
                                v-bind:value="this.Loan_Id"
                                disabled="disabled" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="MonthPeriod"><strong>Month Period</strong></label>
                            <input
                                type="text"
                                id="MonthPeriod"
                                name="MonthPeriod"
                                v-model="this.MonthPeriod"
                                disabled="disabled" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="MonthPayment"><strong>Month Payment</strong></label>
                            <input
                                type="text"
                                id="MonthPayment"
                                name="MonthPayment"
                                v-model="this.MonthPayment"
                                disabled="disabled" />
                        </div>
                    </div>
                    <br />

                    <!-- Customer Information -->
                    <div class="col-md-12">
                        <h4 class="header-blue-tf-color"><strong></strong>Customer Information</h4>
                    </div>
                    <br /><br /><br />

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Identification"><strong>ID</strong></label>
                            <input
                                type="text"
                                id="Identification"
                                name="Identification"
                                required />
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="FirstName"><strong>First Name</strong></label>
                            <input
                                type="text"
                                id="FirstName"
                                name="FirstName"
                                required />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="LastName"><strong>Last Name</strong></label>
                            <input
                                type="text"
                                id="LastName"
                                name="LastName"
                                required />
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Email"><strong>Email Address</strong></label>
                            <input
                                type="email"
                                id="Email"
                                name="Email"
                                required />
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Telephone"><strong>Phone Number</strong></label>
                            <input
                                type="number"
                                id="Telephone"
                                name="Telephone"
                                required />
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Address"><strong>Address</strong></label>
                            <input
                                type="text"
                                id="Address"
                                name="Address"
                                required />
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="EmployeerName"><strong>Employer Name</strong></label>
                            <input
                                type="text"
                                id="EmployeerName"
                                name="EmployeerName"
                                required />
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group">
                            <label><strong>Estado Civil</strong></label>

                            <div class="row">
                                <div
                                    class="col"
                                    style="display: flex; align-items: center">
                                    <input
                                        required
                                        type="radio"
                                        name="MaritalStatus"
                                        id="opcion1"
                                        value="1"
                                        v-model="MaritalStatus"
                                        style="width: auto !important; height: auto !important" />
                                    <label
                                        for="opcion1"
                                        style="margin: 0 2px 1px"
                                        >Casa</label
                                    >
                                </div>

                                <div
                                    class="col"
                                    style="display: flex; align-items: center">
                                    <input
                                        required
                                        type="radio"
                                        name="MaritalStatus"
                                        id="opcion2"
                                        value="2"
                                        v-model="MaritalStatus"
                                        style="width: auto !important; height: auto !important" />
                                    <label
                                        for="opcion2"
                                        style="margin: 0 2px 1px"
                                        >Soltero/-a</label
                                    >
                                </div>

                                <div
                                    class="col"
                                    style="display: flex; align-items: center">
                                    <input
                                        required
                                        type="radio"
                                        name="MaritalStatus"
                                        id="opcion3"
                                        value="3"
                                        v-model="MaritalStatus"
                                        style="width: auto !important; height: auto !important" />
                                    <label
                                        for="opcion3"
                                        style="margin: 0 2px 1px"
                                        >Biba Hunto</label
                                    >
                                </div>

                                <div
                                    class="col"
                                    style="display: flex; align-items: center">
                                    <input
                                        required
                                        type="radio"
                                        name="MaritalStatus"
                                        id="opcion4"
                                        value="4"
                                        v-model="MaritalStatus"
                                        style="width: auto !important; height: auto !important" />
                                    <label
                                        for="opcion4"
                                        style="margin: 0 2px 1px"
                                        >Otro</label
                                    >
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="col-md-12"
                        v-if="this.MaritalStatus == 1">
                        <div class="form-group">
                            <label for="SpocesFullName"><strong>Spouse's first and last name</strong></label>
                            <input
                                type="text"
                                id="SpocesFullName"
                                name="SpocesFullName"
                                v-model="this.SpoucesFullName"
                                placeholder="Type the name..."
                                required />
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div>
                            <div class="form-group">
                                <label for="Address"><strong>Bo persona tin debe existente? Na unda?</strong></label>
                                <div class="row">
                                    <div
                                        class="col-md-3"
                                        v-for="(item, index) in ListBankingEntities"
                                        :key="index"
                                        style="display: flex; align-items: center">
                                        <input
                                            type="radio"
                                            required
                                            v-model="SelectedBankEntities"
                                            :value="item.Id"
                                            :id="item.Id"
                                            style="width: auto !important; height: auto !important" />
                                        <label
                                            :for="item.Id"
                                            style="margin: 0 2px 1px"
                                            text-align="center"
                                            >{{ item.Description }}</label
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="col-md-6"
                        v-if="this.SelectedBankEntities >= 1">
                        <div class="form-group">
                            <label for="ExistingDebtAmount"><strong>Amount of existing debt</strong></label>
                            <input
                                type="number"
                                id="ExistingDebtAmount"
                                name="ExistingDebtAmount"
                                v-model="this.ExistingDebtAmount"
                                placeholder="AWG 0.00" />
                        </div>
                    </div>

                    <div
                        class="col-md-6"
                        v-if="this.SelectedBankEntities == 9">
                        <div class="form-group">
                            <label for="OtherInstitutionName"><strong>Name of institution</strong></label>
                            <input
                                type="text"
                                id="OtherInstitutionName"
                                name="OtherInstitutionName"
                                v-model="this.OtherInstitutionName"
                                placeholder="Type the name..." />
                        </div>
                    </div>

                    <div class="col-md-6"></div>

                    <!-- <div class="col-md-12">
						<h4 class="header-blue-tf-color">Co-Applicant Information</h4>
					</div>
					<br /><br /><br /> -->

                    <!-- Co-Applicant Information -->
                    <!-- <div class="col-md-6">
						<div class="form-group">
							<label for="CoApplicantIDNumber"><strong>Co-Applicant ID</strong></label>
							<input type="text" id="CoApplicantIDNumber" name="CoApplicantIDNumber" />
						</div>
					</div>

					<div class="col-md-6">
						<div class="form-group">
							<label for="CoApplicantFirstName"><strong>Co-Applicant FirstName</strong></label>
							<input type="text" id="CoApplicantFirstName" name="CoApplicantFirstName" />
						</div>
					</div>

					<div class="col-md-6">
						<div class="form-group">
							<label for="CoApplicantLastName"><strong>Co-Applicant LastName</strong></label>
							<input type="text" id="CoApplicantLastName" name="CoApplicantLastName" />
						</div>
					</div>

					<div class="col-md-6">
						<div class="form-group">
							<label for="CoApplicantPhone1"><strong>Co-Applicant Phone</strong></label>
							<input type="text" id="CoApplicantPhone1" name="CoApplicantPhone1" />
						</div>
					</div> -->

                    <br />
                    <!-- Customer documents -->
                    <div class="col-md-12">
                        <h4 class="header-blue-tf-color">Customer Documents</h4>
                    </div>
                    <br /><br />

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="DocumentId"><strong>ID</strong></label>
                            <input
                                type="file"
                                class="form-control"
                                name="DocumentId"
                                id="DocumentId"
                                style="min-height: 0px !important; color: #6bd6d3 !important" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Payslip"><strong>Payslip #1</strong></label>
                            <input
                                type="file"
                                class="form-control"
                                name="Payslip"
                                id="Payslip"
                                style="min-height: 0px !important; color: #6bd6d3 !important" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Others"><strong>Payslip #2</strong></label>
                            <input
                                type="file"
                                class="form-control"
                                name="Others"
                                id="Others"
                                style="min-height: 0px !important; color: #6bd6d3 !important" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="Quote"><strong>Other (if applicable)</strong></label>
                            <input
                                type="file"
                                class="form-control"
                                name="Quote"
                                id="Quote"
                                style="min-height: 0px !important; color: #6bd6d3 !important" />
                        </div>
                    </div>
                    <div class="col-md-12">
                        <button
                            type="submit"
                            id="BtnSendForm"
                            class="btn style1 w-100 d-block">
                            Send
                        </button>
                    </div>

                    <br /><br /><br /><br />
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="row">
                                <div
                                    class="col-md-12"
                                    style="display: flex; align-items: center">
                                    <input
                                        type="checkbox"
                                        id="AgreeTermsAndConditions"
                                        v-model="this.AgreeTermsAndConditions"
                                        style="width: auto !important; height: auto !important" />
                                    <label
                                        for="AgreeTermsAndConditions"
                                        style="margin: 0 8px 1px"
                                        text-align="center">
                                        By checking this box, I confirm that I accept the terms and conditions of
                                        Total's services.
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import $ from "jquery";
    import Swal from "sweetalert2";

    export default {
        name: "Apply",
        data() {
            return {
                // UrlServerIP: "http://localhost:52125",
                UrlServerIP: "https://partner.madworksglobal.com",
                urlApplication: "https://qwihi-api-partners.mindsetgx.app/customers/api/application",
                UrlServerPORT: "",
                MaritalStatus: 0,
                SpoucesFullName: "",
                ListBankingEntities: [
                    { Id: 0, Description: "No tin debe" },
                    { Id: 1, Description: "Aruba Bank" },
                    { Id: 2, Description: "Banco di Caribe" },
                    { Id: 3, Description: "CMB" },
                    { Id: 4, Description: "RBC" },
                    { Id: 5, Description: "APFA" },
                    { Id: 6, Description: "Volkskredietbank" },
                    { Id: 7, Description: "FCCA" },
                    { Id: 8, Description: "Island Finance" },
                    { Id: 9, Description: "Other" },
                ],
                SelectedBankEntities: 0,
                HasExistingDebt: false,
                ExistingDebtAmount: "",
                HasExistingDebtWithOther: false,
                OtherInstitutionName: "",
                AgreeTermsAndConditions: false,
                documentId: null,
                payslip1: null,
                payslip2: null,
                other: null,
            };
        },
        props: {
            LoanAmount: {
                type: String,
                required: false,
            },
            Loan_Id: {
                type: String,
                required: false,
            },
            MonthPeriod: {
                type: String,
                required: false,
            },
            MonthPayment: {
                type: String,
                required: false,
            },
        },
        methods: {
            sendLoanData(LoanApplicationId) {
                const url = "https://qwihi-api-ocs.mindsetgx.app/customers/Api/Profiles/AddDocuments";
                // const url = "http://localhost:7271/Api/Profiles/AddDocuments";

                var formData = new FormData();
                formData.append("DocumentId", document.getElementById("DocumentId").files[0]);
                formData.append("Payslip", document.getElementById("Payslip").files[0]);
                formData.append("Others", document.getElementById("Others").files[0]);
                formData.append("Quote", document.getElementById("Quote").files[0]);
                formData.append("LoanApplicationId", LoanApplicationId);

                fetch(url, {
                    method: "POST",
                    body: formData,
                })
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error("Network response was not ok");
                        }
                        return response.json();
                    })
                    .then((data) => {
                        Swal.fire({
                            icon: "success",
                            title: '<h2 class="swal2-title" id="swal2-title" style="display: block; color: #FFFFFF;">Ok!</h2>',
                            text: "The application was created successfully!",
                            background: "#0A505D",
                            color: "#FFFFFF",
                            iconColor: "#6BD6D3",
                            confirmButtonColor: "#6BD6D3",
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                // this.$router.push("/");
                                window.location.href = "/";
                            }
                        });
                        // this.$router.push("/");
                        window.location.href = "/";
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                        $("#BtnSendForm").attr("disabled", false);
                    });
            },

            convertToBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });
            },
            async sendData() {
                try {
                    const fileId = document.getElementById("DocumentId").files[0];
                    const filePayslip = document.getElementById("Payslip").files[0];
                    const filePayslip2 = document.getElementById("Others").files[0];
                    const fileQuote = document.getElementById("Quote").files[0];

                    const requestBody = {
                        partnerProfileId: 1,
                        productTypeId: 1,
                        amount: $("#LoanAmount").val(),
                        installment: $("#MonthPeriod").val(),
                        monthlyPayment: $("#MonthPayment").val(),
                        customerProfile: {
                            nationalId: $("#Identification").val(),
                            firstName: $("#FirstName").val(),
                            lastName: $("#LastName").val(),
                            address1: $("#Address").val(),
                            phone1: $("#Telephone").val(),
                            email1: $("#Email").val(),
                            employerName: $("#EmployeerName").val(),
                            filesBase64: [
                                fileId && {
                                    documentTypeId: 1,
                                    name: fileId.name,
                                    fileBase64: await this.convertToBase64(fileId),
                                },
                                filePayslip && {
                                    documentTypeId: 2,
                                    name: filePayslip.name,
                                    fileBase64: await this.convertToBase64(filePayslip),
                                },
                                fileQuote && {
                                    documentTypeId: 3,
                                    name: fileQuote.name,
                                    fileBase64: await this.convertToBase64(fileQuote),
                                },
                                filePayslip2 && {
                                    documentTypeId: 2,
                                    name: filePayslip2.name,
                                    fileBase64: await this.convertToBase64(filePayslip2),
                                },
                            ].filter((file) => file && file.fileBase64),
                        },
                    };

                    const response = await axios.post(`${this.urlApplication}`, requestBody, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });

                    Swal.fire({
                        icon: "success",
                        title: '<h2 class="swal2-title" id="swal2-title" style="display: block; color: #FFFFFF;">Ok!</h2>',
                        text: "Loan request has been sent to Total.",
                        background: "#0A505D",
                        color: "#FFFFFF",
                        iconColor: "#6BD6D3",
                        confirmButtonColor: "#6BD6D3",
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = "/";
                        }
                    });
                    $("#BtnSendForm").attr("disabled", false);

                    return response.data;
                } catch (error) {
                    console.error("Error sending loan request:", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "An error occurred while processing your request.",
                        background: "#0A505D",
                        color: "#FFFFFF",
                        iconColor: "#FF6B6B",
                        confirmButtonColor: "#FF6B6B",
                    });
                    $("#BtnSendForm").attr("disabled", false);
                }
            },
            Sendform() {
                if (this.AgreeTermsAndConditions == false) {
                    Swal.fire({
                        icon: "warning",
                        title: '<h2 class="swal2-title" id="swal2-title" style="display: block; color: #FFFFFF;">Please!</h2>',
                        text: "Accept the terms and conditions of our services to complete the form.",
                        background: "#0A505D",
                        color: "#FFFFFF",
                        iconColor: "#6BD6D3",
                        confirmButtonColor: "#6BD6D3",
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        allowOutsideClick: false,
                    });
                    return false;
                }
                $("#BtnSendForm").attr("disabled", true);

                this.sendData();

                // var payload = {
                //     id: 0,
                //     reasonTypeId: 1,
                //     firstName: $("#FirstName").val(),
                //     lastName: $("#LastName").val(),
                //     middleName: "",
                //     titleId: 1,
                //     cityId: 1,
                //     address1: $("#Address").val(),
                //     address2: "",
                //     address3: "",
                //     phone1: $("#Telephone").val(),
                //     phone2: "",
                //     email1: $("#Email").val(),
                //     email1IsVerified: false,
                //     email2: "",
                //     email2IsVerified: false,
                //     migrationOldId: 0,
                //     additionalDetails: {
                //         id: 0,
                //         identificationTypeId1: 2,
                //         identificationType1: $("#Identification").val(),
                //         identificationTypeId2: 1,
                //         identificationType2: "",
                //         customerId: 0,
                //         placeOfBirthId: 1,
                //         nationalityId: 1,
                //         birthDate: null,
                //         bankId: -1,
                //         bankAccountNumber: "",
                //         gender: "F",
                //         maritalStatusId: this.MaritalStatus,
                //         existingBankDebt: this.SelectedBankEntities,
                //         otherInstitutionName: this.OtherInstitutionName,
                //         createdBy: 1,
                //         createDate: "2024-04-16T04:00:06.366Z",
                //         isActive: true,
                //     },
                //     employments: {
                //         id: 0,
                //         employerName: $("#EmployeerName").val(),
                //         fromDate: "2024-01-01",
                //         monthlySalary: 0,
                //         customerId: 0,
                //         clasificationId: 0,
                //         isVerified: false,
                //         createdBy: 1,
                //         createDate: "2024-04-16T04:00:06.366Z",
                //         isActive: true,
                //     },
                //     loanApplicationInfo: {
                //         id: 0,
                //         loanAmount: this.LoanAmount,
                //         periodInMonths: this.MonthPeriod,
                //         monthlyPayment: this.MonthPayment,
                //         agentId: 1,
                //         emailSent: true,
                //         loanTypeId: this.Loan_Id,
                //         customerId: 0,
                //         createdBy: 1,
                //         isActive: true,
                //         origin: 2,
                //     },
                //     createdBy: 1,
                //     createDate: "2024-04-16T04:00:06.366Z",
                //     isActive: true,
                //     origin: 0,
                // };

                // if (this.maritalStatusId == 1) {
                //     payload.additionalDetails.spousesFullName = this.SpoucesFullName;
                // }

                // if (this.SelectedBankEntities >= 1) {
                //     payload.additionalDetails.existingDebtAmount = this.ExistingDebtAmount;
                // }

                // const profileAddUrl = "https://qwihi-api-ocs.mindsetgx.app/customers/Api/Profiles/Add";
                // // const profileAddUrl = "http://localhost:7271/Api/Profiles/Add";
                // fetch(profileAddUrl, {
                //     method: "POST",
                //     headers: {
                //         "Content-Type": "application/json",
                //     },
                //     body: JSON.stringify(payload),
                // })
                //     .then((response) => {
                //         if (!response.ok) {
                //             throw new Error("Error");
                //         }
                //         return response.json();
                //     })
                //     .then((data) => {
                //         this.sendLoanData(data.dataResult);
                //     })
                //     .catch((error) => {
                //         console.error("Error:", error);
                //         $("#BtnSendForm").attr("disabled", false);
                //     });
            },
            checkIfValueExists() {
                if (this.SelectedBankEntities == "10") {
                    this.SelectedBankEntities = this.SelectedBankEntities.filter((selected) => selected == "10");

                    this.HasExistingDebt = true;
                    this.ExistingDebtAmount = "";

                    this.HasExistingDebtWithOther = true;
                    this.OtherInstitutionName = "";
                } else if (this.selectedValue == "1") {
                    this.SelectedBankEntities = this.SelectedBankEntities.filter((selected) => selected == "1");

                    this.HasExistingDebt = false;
                    this.ExistingDebtAmount = "";

                    this.HasExistingDebtWithOther = false;
                    this.OtherInstitutionName = "";
                } else {
                    this.SelectedBankEntities = this.SelectedBankEntities.filter(
                        (selected) => selected != "1" && selected != "10"
                    );

                    this.HasExistingDebt = true;

                    this.HasExistingDebtWithOther = false;
                    this.OtherInstitutionName = "";
                }
            },
        },
    };
</script>
